<template>
  <Main center>
    <div v-if="version === 'v2'" class="card-details">
      <div class="card-details__header">
        <H h2>All Set!</H>

        <P
          >We're excited to help you lease whatever you're looking for. Show this virtual visa card to the merchant to
          proceed.</P
        >
      </div>

      <div class="card-details__card">
        <CardV2 number="**** **** **** ****" valid="**/**" cvv="***" />
      </div>
    </div>

    <div v-else-if="version === 'v3' && loading" class="card-details--loading">
      <div class="card-details__loader">
        <Spinner large dark />
      </div>
    </div>

    <div v-else-if="version === 'v3' && !loading" class="card-details">
      <div class="card-details__header">
        <H h2 v-if="inactive">Your card inactive</H>

        <H h2 v-else>You’re All Set!</H>

        <P v-if="inactive"
          >This is a one-time-use card with an expiration date of {{ kafeneExpDate }}. If you would like to use this
          card, please reapply.
        </P>

        <P v-else>Congrats, your virtual card is ready<br />to be used at {{ merchantName }} - {{ storeName }}!</P>
      </div>

      <div v-if="error" class="card-details__alert">
        <Toast error>{{ error }}</Toast>
      </div>

      <div v-if="success" class="card-details__alert">
        <Toast success>{{ success }}</Toast>
      </div>

      <div class="card-details__card">
        <div v-if="!inactive" class="card-details__approved">
          <H h4 class="card-details__approved-text">Approved for:</H>

          <H h4 class="card-details__approved-text">{{ approvalAmount }}</H>
        </div>

        <CardV3 :number="number" :valid="valid" :cvv="cvv" :preview="preview" :name="name" :inactive="inactive" />

        <div class="card-details__cta">
          <div class="card-details__button-shadow">
            <Button
              v-if="preview"
              large
              dataElement="openInNewTab"
              @click="showCardDetails"
              :disabled="ctaLoading"
              :loading="ctaLoading"
              >Open in New Tab</Button
            >

            <Button v-else-if="inactive" large to="/about-you">Apply Now</Button>

            <Button v-else-if="!inactive && !preview" large @click="copyNumber">Copy Card Number</Button>
          </div>
        </div>

        <div v-if="!inactive" class="card-details__info-box">
          <P small2 regular colorInherit
            >Card is <strong>one-time-use</strong>, expires in <strong>7 days</strong>, and can only be used at
            {{ merchantName }} - {{ storeName }}. This card will be declined if used at any other stores.</P
          >
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

import { moneyMask, repeatedCharMask, formatDateString } from '../helpers/general'
import { isCardExpired } from '../helpers/validators'

import Button from '../components/common/Button.vue'
import CardV2 from '../components/CardV2.vue'
import CardV3 from '../components/CardV3.vue'
import H from '../components/common/H.vue'
import P from '../components/common/P.vue'
import Main from '../components/Main'
import Spinner from '../components/common/Spinner.vue'
import Toast from '../components/common/Toast.vue'

export default {
  name: 'CardDetails',
  components: {
    Button,
    CardV2,
    CardV3,
    H,
    P,
    Main,
    Spinner,
    Toast
  },
  setup() {
    const store = useStore()

    const version = store.getters['application/getVersion']

    const cardAuthToken = store.getters['application/getCardAuthToken']

    return {
      version,
      store: useStore(),
      error: ref(''),
      success: ref(''),
      number: ref('1234 5678 1234 5678'),
      valid: ref('01/01'),
      cvv: ref('123'),
      loading: ref(false),
      ctaLoading: ref(false),
      cardAuthToken,
      inactive: ref(false),
      preview: ref(true),
      kafeneExpDate: ref('')
    }
  },
  computed: {
    activeCardId() {
      return this.store.getters['application/getActiveCardId']
    },
    approvalAmount() {
      return moneyMask(this.store.getters['application/getAmount'])
    },
    name() {
      return this.store.getters['application/getName']
    },
    merchantName() {
      return this.store.getters['application/getMerchantName']
    },
    storeName() {
      return this.store.getters['application/getStoreName']
    }
  },
  mounted() {
    if (this.cardAuthToken) {
      this.store.commit('application/setVersion', 'v3')
      this.fetchCardDetails()
    }
  },
  methods: {
    async fetchCardDetails() {
      this.loading = true
      this.preview = false
      this.error = false

      const { success, data, message } = await this.store.dispatch('application/getCardDetails')

      if (!success) {
        this.loading = false
        this.error = message
        this.inactive = true
        return
      }

      const { cvc, expMonth, expYear, number, cardStripeStatus, kafeneExpDate } = data

      this.number = repeatedCharMask(number, 4, ' ')
      this.valid = `${('0' + expMonth).slice(-2)}/${expYear.toString().slice(-2)}`
      this.cvv = cvc
      this.kafeneExpDate = formatDateString(new Date(kafeneExpDate))

      if (cardStripeStatus !== 'active' || isCardExpired(new Date(kafeneExpDate))) {
        this.inactive = true
      }

      this.loading = false
    },
    async showCardDetails() {
      const w = window.open('', '_blank')

      this.ctaLoading = true
      this.error = ''

      const { success, data, message } = await this.store.dispatch('application/getCardAccessLink')

      if (!success) {
        w.close()
        this.error = message
        this.ctaLoading = false
        return
      }

      const { shortLink } = data

      w.location = shortLink

      this.ctaLoading = false
    },
    copyNumber() {
      this.$copyText(this.number)
        .then(() => {
          this.success = 'Card number copied!'
        })
        .catch(() => {
          this.error = "Can't copy card number"
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-details {
  &__header {
    max-width: 360px;
    margin: 0 auto;
    margin-top: 16px;

    @include media-query(sm) {
      margin-top: 32px;
    }

    @include media-query(md) {
      margin-top: 64px;
    }

    > * + * {
      margin-top: 16px;
    }
  }

  &__alert {
    max-width: 360px;
    margin: 0 auto;
    margin-top: 24px;
  }

  &__approved {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    background-color: rgba(90, 217, 172, 0.2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-bottom: 24px;
    margin-bottom: -12px;
  }

  &__approved-text {
    color: get-color(text-1);
  }

  &__card {
    margin: 0 auto;
    margin-top: 24px;
    max-width: 324px;

    > img,
    svg {
      margin: 0 auto;
    }

    > a {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #6b78eb;
      margin-top: 16px;
    }
  }

  &__info-box {
    margin-top: 40px;
    background-color: rgba(90, 217, 172, 0.2);
    border-radius: 8px;
    color: get-color(text-1);
    padding: 16px 12px;
  }

  &__cta {
    position: relative;
    z-index: 10;
    margin-top: -24px;

    > * + * {
      margin-top: 24px;
    }
  }

  &__button-shadow {
    display: inline-block;
    box-shadow: 0px 15px 36px -14px #5ad9ac;
  }

  &--loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -12px;
  }
}
</style>
