<template>
  <div
    class="card"
    :class="{
      'card--inactive': inactive
    }"
  >
    <div v-if="inactive" class="card__badge">
      <P colorInherit tiny2>Inactive</P>
    </div>

    <div class="card__header">
      <div class="card__logo">
        <LogoDirect />
      </div>

      <p v-if="!inactive" class="card__label">Virtual Card</p>
    </div>

    <div v-if="!preview" class="card__main">
      <P class="card__label">Card Number</P>

      <P class="card__data">{{ number }}</P>
    </div>

    <div class="card__footer" :class="{ 'card__footer--preview': preview }">
      <div v-if="preview" class="card__box">
        <P class="card__data card__data--xsmall">{{ name }}</P>

        <P class="card__data card__data--spaced card__data--small card__data--blurred">{{ number }}</P>
      </div>

      <div v-if="!preview" class="card__box">
        <P class="card__label">Security Code</P>

        <P class="card__data card__data--small">{{ cvv }}</P>
      </div>

      <div v-if="!preview" class="card__box">
        <P class="card__label">Valid Thru</P>

        <P class="card__data card__data--small">{{ valid }}</P>
      </div>

      <div class="card__box">
        <P v-if="!preview" class="card__label card__label--right">Debit</P>

        <P class="card__visa">
          <img src="@/assets/visa-logo.png" alt="Visa" />
        </P>
      </div>
    </div>
  </div>
</template>

<script>
import LogoDirect from './common/LogoDirect.vue'
import P from './common/P.vue'

export default {
  components: {
    P,
    LogoDirect
  },
  props: {
    preview: Boolean,
    name: String,
    number: String,
    valid: String,
    cvv: String,
    inactive: Boolean,
    blurred: Boolean
  }
}
</script>

<style lang="scss" scoped>
.card {
  $this: &;

  position: relative;
  width: 100%;
  padding: 24px;
  padding-bottom: 40px;
  margin: 0 auto;
  border-radius: 8px;
  text-align: left;
  background-color: #f2f3f7;
  overflow: hidden;
  box-shadow: 8px 8px 30px rgba(14, 21, 81, 0.19);

  &__badge {
    position: absolute;
    right: 30px;
    top: 30px;
    transform: translate(50%, -50%) rotate(45deg);
    background-color: get-color(primary-4);
    color: #fff;
    text-transform: uppercase;
    padding: 6px 40px;
  }

  &__header,
  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__main {
    margin-top: 16px;
  }

  &__footer {
    margin-top: 8px;
    align-items: flex-start;

    &--preview {
      margin-top: 56px;
      margin-bottom: 16px;
      align-items: center;
    }
  }

  &__box {
    flex: 0 0 auto;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  &__logo {
    width: 100%;
    max-width: 150px;
  }

  &__label {
    font-size: 10px;
    line-height: 24px;
    color: #74757c;
    text-transform: uppercase;

    &--right {
      text-align: right;
    }
  }

  &__data {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2em;
    color: #292b35;

    &--spaced {
      margin-top: 4px;
    }

    &--small {
      font-size: 16px;
    }

    &--xsmall {
      font-size: 12px;
    }

    &--blurred {
      color: transparent;
      text-shadow: 0 0 8px rgba(get-color(text-1), 0.5);
    }
  }

  &__visa {
    img {
      width: 100%;
      max-width: 70px;
    }
  }

  &--inactive {
    #{$this}__data {
      color: #d9dade;
    }
  }
}
</style>
