<template>
  <div class="card">
    <div class="card__header">
      <div class="card__logo">
        <LogoDirect />
      </div>

      <p class="card__label">Virtual Card</p>
    </div>

    <div class="card__main">
      <P class="card__label">Card Number</P>

      <P class="card__data">{{ number }}</P>
    </div>

    <div class="card__footer">
      <div class="card__box">
        <P class="card__label">Security Code</P>

        <P class="card__data card__data--small">{{ cvv }}</P>
      </div>

      <div class="card__box">
        <P class="card__label">Valid Thru</P>

        <P class="card__data card__data--small">{{ valid }}</P>
      </div>

      <div class="card__box">
        <P class="card__label card__label--right">Debit</P>

        <P class="card__visa">
          <img src="@/assets/visa-logo.png" alt="Visa" />
        </P>
      </div>
    </div>
  </div>
</template>

<script>
import LogoDirect from './common/LogoDirect.vue'
import P from './common/P.vue'

export default {
  components: {
    P,
    LogoDirect
  },
  props: {
    number: String,
    valid: String,
    cvv: String
  }
}
</script>

<style lang="scss" scoped>
.card {
  $this: &;

  position: relative;
  width: 100%;
  padding: 24px;
  margin: 0 auto;
  border: 1px solid #a9aaae;
  border-radius: 8px;
  text-align: left;

  &__header,
  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__main {
    margin-top: 16px;
  }

  &__footer {
    margin-top: 8px;
    align-items: flex-start;
  }

  &__box {
    flex: 0 0 auto;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  &__logo {
    width: 100%;
    max-width: 150px;
  }

  &__label {
    font-size: 10px;
    line-height: 24px;
    color: #74757c;
    text-transform: uppercase;

    &--right {
      text-align: right;
    }
  }

  &__data {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2em;
    color: #292b35;

    &--small {
      font-size: 16px;
    }
  }

  &__visa {
    img {
      width: 100%;
      max-width: 40px;
    }
  }
}
</style>
