<template>
  <svg viewBox="0 0 150 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.658 4.776v19.067c0 .625-.25 1.223-.693 1.665a2.374 2.374 0 01-1.674.689H2.367c-.628 0-1.23-.248-1.674-.69A2.347 2.347 0 010 23.844V7.137a2.34 2.34 0 01.694-1.664 2.366 2.366 0 011.673-.687l8.291-.01zM18.46 18.291a2.353 2.353 0 00-1.717-.737c-.628 0-1.23.248-1.675.69a2.347 2.347 0 00-.693 1.664v6.29c0 .623.25 1.221.694 1.662.444.44 1.046.689 1.673.689h6.297c.43 0 .85-.117 1.218-.337a2.343 2.343 0 00.497-3.639l-6.294-6.282z"
      fill="#00E091"
    />
    <path
      opacity=".45"
      d="M2.367 26.197l8.281-8.233V4.776h-8.28a2.377 2.377 0 00-1.674.688A2.35 2.35 0 000 7.127v16.707c0 .624.25 1.223.693 1.664.444.441 1.046.69 1.674.69v.009z"
      fill="url(#paint0_radial)"
    />
    <path
      d="M8.299 2.346V14.65c0 .458.135.906.387 1.288.253.383.612.684 1.034.867a2.378 2.378 0 002.555-.435l12.57-12.5.04-.045c.35-.41.538-.935.526-1.474A2.338 2.338 0 0024.72.688 2.364 2.364 0 0023.047 0h-12.38c-.628 0-1.229.246-1.673.686a2.344 2.344 0 00-.695 1.66z"
      fill="#00E091"
    />
    <path
      d="M59.01 7.687a3.616 3.616 0 00-1.414-.27c-2.01 0-3.255 1.204-3.255 3.145v9.68h1.483v-7.37h2.664v-1.276h-2.696v-.943c0-1.312.627-1.966 1.865-1.966.403-.005.803.067 1.18.212l.029.012.175-1.184-.015-.027-.017-.013zM39.606 7.756h2.046l-4.46 5.093 4.514 7.368h-1.935l-3.721-6.102-2.269 2.51v3.592h-1.658V7.757h1.658v6.613l5.825-6.614z"
      fill="#000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.002 11.424c1.408 0 2.58.55 3.306 1.533v-1.353h1.483v8.638h-1.483v-1.364c-.731.995-1.9 1.54-3.306 1.54-2.496 0-4.38-1.972-4.38-4.522 0-2.55 1.884-4.472 4.38-4.472zm-2.909 4.464c0 1.823 1.312 3.194 3.055 3.194h-.013a3.175 3.175 0 002.26-.93 3.139 3.139 0 00.913-2.256 3.061 3.061 0 00-.906-2.242 3.1 3.1 0 00-2.254-.9c-1.743 0-3.055 1.311-3.055 3.134zM59.387 15.935c0-2.607 1.88-4.498 4.47-4.498a4.183 4.183 0 013.272 1.398 4.393 4.393 0 01.873 3.611v.02h-7.058c.24 1.562 1.542 2.572 3.329 2.572a5.982 5.982 0 003.056-.794l.03-.014.262 1.346h-.015a6.372 6.372 0 01-3.276.855c-2.911 0-4.943-1.848-4.943-4.496zm1.582-.668h5.671v-.012c-.114-1.494-1.154-2.445-2.738-2.445s-2.788 1.007-2.933 2.457z"
      fill="#000"
    />
    <path
      d="M74.7 11.437a3.387 3.387 0 00-2.863 1.407v-1.228h-1.483v8.638h1.483v-4.887c0-1.474 1.114-2.552 2.649-2.552 1.534 0 2.424.897 2.424 2.526v4.9h1.47v-5.25c-.01-2.233-1.383-3.554-3.68-3.554z"
      fill="#000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M86.786 11.765c.564.244 1.067.61 1.472 1.07a4.386 4.386 0 01.867 3.611v.02H82.07c.237 1.562 1.542 2.572 3.329 2.572a5.988 5.988 0 003.064-.784l.03-.014.261 1.336h-.014c-.995.57-2.125.86-3.272.843-2.911 0-4.943-1.848-4.943-4.496 0-2.607 1.88-4.499 4.47-4.499a4.178 4.178 0 011.791.341zm-4.692 3.49h5.684c-.114-1.494-1.166-2.457-2.75-2.457s-2.788 1.007-2.934 2.457z"
      fill="#000"
    />
    <path
      d="M96.85 7.505v13.014h5.606c4.15 0 6.574-2.482 6.574-6.517 0-4.006-2.376-6.497-6.574-6.497H96.85zm5.328 10.781h-2.74V9.738h2.74c2.913 0 4.198 1.543 4.198 4.264 0 2.703-1.256 4.284-4.198 4.284zM113.598 20.519v-9.2h-2.463v9.2h2.463zm-1.236-10.743c.833 0 1.418-.575 1.418-1.409 0-.843-.585-1.418-1.418-1.418-.834 0-1.409.584-1.409 1.418s.575 1.409 1.409 1.409zM118.482 11.319h-2.377v9.2h2.463v-4.715c0-1.898 1.141-2.482 2.224-2.482.488 0 .891.124 1.14.21l.192-2.194a3.729 3.729 0 00-1.198-.21c-1.035 0-1.927.488-2.396 1.446l-.048-1.255zM131.435 17.855c-.91.431-1.974.7-3.114.7-1.515 0-2.665-.614-2.914-1.898h6.651c.604-3.402-1.543-5.53-4.37-5.53-2.731 0-4.725 1.974-4.725 4.792 0 2.865 2.09 4.792 5.348 4.792 1.342 0 2.578-.355 3.469-.863l-.345-1.993zm-5.99-2.847c.106-1.044.988-1.81 2.301-1.81 1.265 0 2.041.785 2.108 1.81h-4.409zM133.645 15.91c0 2.846 2.157 4.8 5.185 4.8 1.121 0 2.013-.248 2.98-.68l-.287-2.185c-.795.374-1.6.604-2.53.604-1.668 0-2.885-.978-2.885-2.55 0-1.657 1.284-2.52 2.962-2.52.91 0 1.753.211 2.415.499l.21-2.137a7.016 7.016 0 00-2.894-.614c-2.971 0-5.156 1.792-5.156 4.782zM149.454 13.36V11.32h-2.664V8.367h-2.386v2.952h-1.562v2.041h1.552v4.14c0 2.166 1.361 3.21 3.383 3.21.748 0 1.658-.143 2.224-.364l-.23-2.04a6.194 6.194 0 01-1.562.22c-.825 0-1.361-.422-1.361-1.38V13.36h2.606z"
      fill="#00E091"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(146.623 7.492 7.749) scale(23.3679 19.9377)"
      >
        <stop offset=".43" stop-color="#06A27E" />
        <stop offset=".58" stop-color="#28BE95" stop-opacity=".65" />
        <stop offset=".71" stop-color="#42D5A8" stop-opacity=".37" />
        <stop offset=".83" stop-color="#55E5B5" stop-opacity=".17" />
        <stop offset=".93" stop-color="#61EEBD" stop-opacity=".04" />
        <stop offset="1" stop-color="#65F2C0" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LogoDirect'
}
</script>
